<template>
  <div class="mobile-container">
    <div class="bg img-cover"><img src="../../assets/images/bofang-bg.jpg" /></div>
    <div class="page-content">
      <div class="close-item img-cover" @click="$router.go(-1)"><img src="../../assets/images/close-black-icon.png" /></div>
      <div class="input-item">
        <div class="input"><input v-model="value" type="text" placeholder="请复制视频链接或图集链接" /></div>
        <div v-if="value" class="btn" @click="handleSubmit">
          <van-loading v-if="submiting" color="#ffffff" />
          <span v-else>确定</span>
        </div>
        <div v-else class="btn" @click="handlePaste">粘贴</div>
      </div>
      <div class="tip-item">{{ goodsInfo.ParamsTemplate && goodsInfo.ParamsTemplate[0] && goodsInfo.ParamsTemplate[0].description }}</div>
      <div class="page-title img-cover"><img src="../../assets/images/bofang-title.png" /></div>
      <div class="list-item">
        <div class="list" :class="listAnimate ? 'animation' : ''" ref="orderListDom">
          <div class="item" v-for="(item, index) in orderList" :key="index">
            <div class="detail">
              <div class="url">链接：{{ item.BuyParams && item.BuyParams[0] && item.BuyParams[0].value }}</div>
              <div class="status">
                <span class="red" v-if="item.OrderStatus == 4">已领取</span>
                <span class="red" v-else-if="item.OrderStatus == 6 || item.OrderStatus == 7">领取失败</span>
                <span class="white" v-else>领取中</span>
              </div>
            </div>
            <div class="time">{{ $TOOL.dateFormat(item.CreateAt * 1000, 'yyyy-MM-dd hh:mm') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "GoodsIsBo",
  data() {
    return {
      id: '',
      goodsInfo: {
        ParamsTemplate: []
      },
      value: '',
      submiting: false,
      orderList: [],
      listAnimate: false,
      timer1: null,
      timer2: null
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  mounted() {
    if (this.$route.query && this.$route.query.id) {
      this.id = this.$route.query.id
      this.handleGetGoodsDetail()
    }
  },
  methods: {
    handlePaste() {
      if (navigator.clipboard) {
        navigator.clipboard.readText().then(text => {
          if (text) {
            this.value = text
          }
        })
      } else {
        this.$toast('粘贴失败')
      }
    },
    async handleGetGoodsDetail() {
      const { id, websiteInfo } = this
      var res = await this.$API.goodsDetail.post({
        Id: id,
        AdminId: websiteInfo.Id
      })
      if (res.error == 0) {
        try {
          res.info.ParamsTemplate = JSON.parse(res.info.ParamsTemplate)
        } catch (error) {
          res.info.ParamsTemplate = []
        }
        this.goodsInfo = res.info
      }
      this.handleGetGoodsOrderList()
    },
    async handleGetGoodsOrderList() {
      const { Id } = this
      const params = {
        PageCount: 20, 
        Page: 1,
        Id: Id
      }
      var res = await this.$API.orderList.post(params)
      const data = []
      for (let index = 0; index < res.info.length; index++) {
        const item = res.info[index]
        data.push({
          ...item,
          BuyParams: JSON.parse(item.BuyParams)
        })
      }
      this.orderList = [
        ...data
      ]
      if (this.orderList.length > 4) {
        this.$nextTick(() => {
          if (this.timer1 != null) {
            clearInterval(this.timer1)
          }
          if (this.timer2 != null) {
            clearInterval(this.timer2)
          }
          this.setOrderListPlay()
        })
      }
    },
    setOrderListPlay() {
      this.listAnimate = true
      this.timer1 = setTimeout(() => {
        this.listAnimate = false
        this.orderList.push(this.orderList.shift())
        this.timer2 = setTimeout(() => {
          this.$nextTick(() => {
            this.setOrderListPlay()
          })
        }, 30);
      }, 3000);

    },
    async handleGetDouYinMasterUrl(data) {
      var res = this.$API.getDouYinMasterUrl.post({
        Url: data
      })
      return res
    },
    async handleGetdouyinkuaishouid(data) {
      var res = this.$API.getdouyinkuaishouid.post({
        Url: data
      })
      return res
    },
    async handleVerifyInput() {
      const { value, goodsInfo } = this
      const item = goodsInfo.ParamsTemplate[0]
      if (item.type == 6 && !this.$TOOL.numberRegex(value)) {
        this.$toast(item.name + '格式不正确')
        return false
      }
      if (item.type == 21 && !this.$TOOL.qqRegex(value)) {
        this.$toast(item.name + '格式不正确')
        return false
      }
      if (item.type == 22 && !this.$TOOL.phoneRegex(value)) {
        this.$toast(item.name + '格式不正确')
        return false
      }
      if (item.type == 23 && !this.$TOOL.emailRegex(value)) {
        this.$toast(item.name + '格式不正确')
        return false
      }
      if (item.type == 61 && value) {
        let res = await this.handleGetDouYinMasterUrl(value)
        if (res.error == 0 && res.info) {
          this.value = res.info
        } else {
          this.$toast(item.name + '格式不正确')
          return false
        }
      }
      if (item.type == 62 && value) {
        let res = await this.handleGetdouyinkuaishouid(value)
        if (res.error == 0 && res.info) {
          this.value = res.info
        } else {
          this.$toast(item.name + '格式不正确')
          return false
        }
      }
      return true
    },
    async handleSubmit() {
      const { submiting, value, goodsInfo } = this
      if (goodsInfo.IsClose == 1) {
        return
      }
      if (submiting) {
        return
      }
      if (!value) {
        this.$toast(goodsInfo.ParamsTemplate[0].name + '不能为空')
        return
      }
      const verify = await this.handleVerifyInput()
      if (!verify) {
        return false
      }
      this.submiting = true
      const orderParams = [{
        name: goodsInfo.ParamsTemplate[0].name || '',
        alias: goodsInfo.ParamsTemplate[0].key || '',
        value: this.value
      }]
      const params = {
        GoodsIds: goodsInfo.Id,
        ZxType: 1,
        OrderNum: goodsInfo.BuyMinLimit,
        OrderParams: JSON.stringify([orderParams]),
        ExpTime: this.$TOOL.dateFormat(new Date())
      }
      var res = await this.$API.addOrder.post(params)
      this.submiting = false
      if (res.error == 0) {
        const data = JSON.parse(res.info)
        this.$dialog.alert({
          type: data[0].error == 0 ? 'success' : 'danger',
          title: '提示',
          message: data[0].msg,
          confirmButtonText: '确定',
        })
        if (data[0].error == 0) {
          this.value = ''
        }
        this.handleGetGoodsOrderList()
      } else {
        this.$dialog.alert({
          type: 'danger',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定',
        })
      }
    },
  }
};
</script>

<style lang="less" scoped>
.mobile-container {
  width: 100%;
  height: 16.24rem;
  background-color: #f7f7f7;
  position: relative;
  .bg {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
  .page-content {
    position: relative;
    overflow: hidden;
    .close-item {
      cursor: pointer;
      position: absolute;
      left: .4rem;
      top: .88rem;
      width: .32rem;
      height: .32rem;
      padding: .04rem;
    }
    .input-item {
      margin: 6.84rem .28rem 0 .36rem;
      padding: 0 .06rem 0 .16rem;
      border-radius: .16rem;
      background-color: #fff;
      height: .72rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .input {
        flex: 1;
      }
      .btn {
        cursor: pointer;
        width: 1.2rem;
        height: .56rem;
        border-radius: .12rem;
        background-color: #f03510;
        box-shadow: 0 0 .12rem 0 rgba(160, 195, 124, .3);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: .24rem;
        font-weight: 500;
      }
    }
    .tip-item {
      margin: 0 .28rem 0 .36rem;
      color: #fff;
      font-size: .24rem;
      line-height: .4rem;
    }
    .page-title {
      margin: .48rem auto;
      width: 2.36rem;
      height: auto;
    }
    .list-item {
      margin: .48rem .24rem 0;
      padding: .12rem .24rem;
      height: 5.76rem;
      background-color: #f8663a;
      overflow: hidden;
      border-radius: .24rem;
      .list {
        transition: none;
        margin-top: -.02rem;
        &.animation {
          transition: all 3s linear;
          margin-top: -1.44rem;
        }
        .item {
          padding: .2rem 0;
          height: 1rem;
          border-bottom: .02rem solid #f25227;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          .detail {
            width: 100%;
            height: .48rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: .28rem;
            .url {
              width: 5.12rem;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: #fcd787;
            }
            .status {
              font-weight: bold;
              .white {
                color: #fff;
              }
              .red {
                color: #f33510;
              }
            }
          }
          .time {
            font-size: .24rem;
            line-height: .48rem;
            color: rgba(255, 255, 255, .6);
          }
        }
      }
    }
  }
}
</style>
